import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'modelo',
    loadChildren: () => import('./pages/modelo/modelo.module').then( m => m.ModeloPageModule)
  },
  {
    path: 'online',
    loadChildren: () => import('./pages/hotspot/online/online.module').then( m => m.OnlinePageModule)
  },
  {
    path: 'hotspotadd',
    loadChildren: () => import('./pages/hotspot/cadastra/cadastra.module').then( m => m.CadastraPageModule)
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/clientes/clientes.module').then( m => m.ClientesPageModule)
  },
  {
    path: 'form-cliente',
    loadChildren: () => import('./forms/form-cliente/form-cliente.module').then( m => m.FormClientePageModule)
  },
  {
    path: 'banda',
    loadChildren: () => import('./pages/banda/banda.module').then( m => m.BandaPageModule)
  },
  {
    path: 'planos',
    loadChildren: () => import('./pages/planos/planos.module').then( m => m.PlanosPageModule)
  },
  {
    path: 'contrato',
    loadChildren: () => import('./pages/contrato/contrato.module').then( m => m.ContratoPageModule)
  },
  {
    path: 'form-contrato',
    loadChildren: () => import('./forms/form-contrato/form-contrato.module').then( m => m.FormContratoPageModule)
  },
  {
    path: 'form-planos',
    loadChildren: () => import('./forms/form-planos/form-planos.module').then( m => m.FormPlanosPageModule)
  },
  {
    path: 'form-banda',
    loadChildren: () => import('./forms/form-banda/form-banda.module').then( m => m.FormBandaPageModule)
  },
  {
    path: 'parametros',
    loadChildren: () => import('./core/parametros/parametros.module').then( m => m.ParametrosPageModule)
  },
  {
    path: 'form-hotspot',
    loadChildren: () => import('./forms/form-hotspot/form-hotspot.module').then( m => m.FormHotspotPageModule)
  },
  {
    path: 'form-logo',
    loadChildren: () => import('./forms/form-logo/form-logo.module').then( m => m.FormLogoPageModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./pages/ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'bilhetes/planos',
    loadChildren: () => import('./pages/planos-tickets/planos-tickets.module').then( m => m.PlanosTicketsPageModule)
  },
  {
    path: 'form-planos-tickets',
    loadChildren: () => import('./forms/form-planos-tickets/form-planos-tickets.module').then( m => m.FormPlanosTicketsPageModule)
  },
  {
    path: 'form-tickets',
    loadChildren: () => import('./forms/form-tickets/form-tickets.module').then( m => m.FormTicketsPageModule)
  },
  {
    path: 'profile-menu',
    loadChildren: () => import('./core/profile-menu/profile-menu.module').then( m => m.ProfileMenuPageModule)
  },
  {
    path: 'campanhas',
    loadChildren: () => import('./pages/campanhas/campanhas.module').then( m => m.CampanhasPageModule)
  },
  {
    path: 'form-campanhas',
    loadChildren: () => import('./forms/form-campanhas/form-campanhas.module').then( m => m.FormCampanhasPageModule)
  },
  {
    path: 'form-campanhas-gerenciar',
    loadChildren: () => import('./forms/form-campanhas-gerenciar/form-campanhas-gerenciar.module').then( m => m.FormCampanhasGerenciarPageModule)
  },
  {
    path: 'form-campanhas-imagens',
    loadChildren: () => import('./forms/form-campanhas-imagens/form-campanhas-imagens.module').then( m => m.FormCampanhasImagensPageModule)
  },
  {
    path: 'revendedores',
    loadChildren: () => import('./pages/revendedores/revendedores.module').then( m => m.RevendedoresPageModule)
  },
  {
    path: 'form-revendedores',
    loadChildren: () => import('./forms/form-revendedores/form-revendedores.module').then( m => m.FormRevendedoresPageModule)
  },
  {
    path: 'criar-lojas',
    loadChildren: () => import('./modules/cadastros/pages/lojas/criar-lojas/criar-lojas.module').then( m => m.CriarLojasPageModule)
  },
  {
    path: 'lojas',
    loadChildren: () => import('./modules/lojas/lojas.module').then( m => m.LojasPageModule)
  },
  {
    path: 'gerar-bilhetes',
    loadChildren: () => import('./pages/gerar-bilhetes/gerar-bilhetes.module').then( m => m.GerarBilhetesPageModule)
  },
  {
    path: 'form-bilhetes',
    loadChildren: () => import('./forms/form-bilhetes/form-bilhetes.module').then( m => m.FormBilhetesPageModule)
  },
  {
    path: 'form-loja-setings',
    loadChildren: () => import('./forms/form-loja-setings/form-loja-setings.module').then( m => m.FormLojaSetingsPageModule)
  },
  {
    path: 'form-loja-produtos',
    loadChildren: () => import('./forms/form-loja-produtos/form-loja-produtos.module').then( m => m.FormLojaProdutosPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
