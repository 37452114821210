import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

console.log(location.host);


 if (location.host !== "localhost:8100") {
    console.log("local")
    var uri: any = "https://api.redelibertywifi.com.br/api";
 }else {
    var uri: any = "http://19.16.0.70:3000/api"; 
 }
@Injectable({
  providedIn: "root",
})

export class ApiService {
 
  url: string = uri;


  /**
   * Classe construtora
   * @param {string} http 
   */
  constructor(public http: HttpClient) {}

  /**
   * Dispara um evento quando efetua login
   */
  moatraMenuEmitter = new EventEmitter();

  /**
   * Efetua login
   * @param param 
   */
  login(param: any) {
    this.moatraMenuEmitter.emit(param);
  }
  /**
   * Efetua uma leitura no banco de dados
   * @param param {any}
   */
  read(param) {
    return this.http.get(this.url + param).toPromise();
  }

  /**
   * Envia o comando de apagar
   * @param {any} param 
   */
  delete(param) {
    return this.http.delete(this.url + param).toPromise();
  }

  /**
   * Envia o parametro de post
   * @param param {any}
   * @param dados {array}
   */
  post(param: any, dados: any) {
    let headers = new HttpHeaders({ "Content-type": "application/json" });

    return this.http
      .post(this.url + param, dados, { headers: headers })
      .toPromise();
  }
  /**
   * Comando para fazer upload imagens para o bac
   * @param param {any}
   * @param dados {any}
   */ 
  postFile(param: any,dados: any) {
    return this.http
      .post(this.url + param, dados)
      .toPromise();
  }

  /**
   * comando para atualizar
   * @param param {any}
   */
  update(param: any) {
    let headers = new HttpHeaders({ "Content-type": "application/json" });

    return this.http.put(this.url, param, { headers: headers }).toPromise();
  }
}
