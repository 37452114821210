import { Component, OnInit } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


import { SwUpdate } from "@angular/service-worker";
import { ApiService } from './api.service';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: "Inicio",
      url: "/home",
      icon: "home",
    },
    {
      title: "Outbox",
      url: "/modelo",
      icon: "paper-plane",
    },
    {
      title: "Favorites",
      url: "/folder/Favorites",
      icon: "heart",
    },
    {
      title: "Archived",
      url: "/folder/Archived",
      icon: "archive",
    },
    {
      title: "Trash",
      url: "/folder/Trash",
      icon: "trash",
    },
    {
      title: "Spam",
      url: "/folder/Spam",
      icon: "warning",
    },
  ];
  public labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];

  campos: any;

  information: any[];
  automaticClose = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private swUpdate: SwUpdate,
    private service: ApiService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.service.moatraMenuEmitter.subscribe((mostrar) => {
      this.loadDados(mostrar);
    });

    if (localStorage.getItem("user_id")) {
      this.loadDados({ user_id: localStorage.getItem("user_id") });
    }

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    if (localStorage.getItem("start")) {
      if(localStorage.getItem("user_id")){
        this.nav.navigateRoot("/home");
      }else{
        this.nav.navigateRoot("/login");
      }
    } else {
      this.nav.navigateRoot("/start");
    }

    this.swUpdate.available.subscribe((event) => {
      console.log(
        "A newer version is now available. Refresh the page now to update the cache"
      );
    });
    this.swUpdate.checkForUpdate();
  }

  loadDados(evento) {
    if (localStorage.getItem("user_id")) {
      this.service
        .post("/menu/login", evento)
        .then((dados: any) => {
          this.campos = dados;
          this.information = dados;

          this.information[0].open = true;
        })
        .catch((err) => console.log(err));
      console.log("Evento emitido", evento);
    }
  }

  toggleSection(index) {
    this.information[index].open = !this.information[index].open;

    if (this.automaticClose && this.information[index].open) {
      this.information
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleItem(index, childIndex) {
    this.information[index].children[childIndex].open = !this.information[index]
      .children[childIndex].open;
  }

  sair() {
    console.log("sair");

    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("empresa_id");

    this.nav.navigateRoot("/login");
  }
}
