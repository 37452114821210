import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-popover-actions',
  templateUrl: './popover-actions.component.html',
  styleUrls: ['./popover-actions.component.scss'],
})
export class PopoverActionsComponent implements OnInit {
  item: any;
  constructor(public navParams: NavParams, private popover: PopoverController, private alerta: AlertController) {
    this.item = navParams.get('id');
  }

  ngOnInit() {
    console.log(this.item)
  }

  edita(){
    this.popover.dismiss({
      action: 'edit',
      id: this.item
    })
  }

  async deleta(){
    let alert = await this.alerta.create({
      message: 'Ten certeza que deseja apagar o registro?',
      buttons: [
        {
          text: "Ok",
          handler: () => {
            this.popover.dismiss({
              action: 'dell',
              id: this.item.id
            })
          }
        },
        {
          text: "Não",
          handler: () => {
            this.popover.dismiss();
            console.log('none')
          }
        }
      ]
    })

    await alert.present();
  }

}
